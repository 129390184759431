<template>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1176 6.00023L10.057 18.0609C9.47119 18.6467 8.52145 18.6467 7.93566 18.0609L1.875 12.0002L3.99632 9.87891L8.99632 14.8789L19.9963 3.87891L22.1176 6.00023Z" fill="#383838"/>
</template>

<script>
    export default {
        name: "YesIcon"
    }
</script>

<style scoped>

</style>