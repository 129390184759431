import { render, staticRenderFns } from "./selected-menu.vue?vue&type=template&id=205ab920&scoped=true"
import script from "./selected-menu.vue?vue&type=script&lang=js"
export * from "./selected-menu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205ab920",
  null
  
)

export default component.exports