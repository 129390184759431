export function scrollTo(id = false, offset = 0, force = false) {
	console.log('scrollTo');
	if (!id) {
		window.scroll({ top: 0, left: 0, behavior: force ? 'instant' : 'smooth' });
	} else {
		const target = document.getElementById(id);
		const header = document.querySelector('.header');
		const headerHeight = header ? header.clientHeight - 1 : 0;
		if (target) {
			const position = target.getBoundingClientRect().top + window.scrollY;
			window.scroll({ top: position - offset - headerHeight, left: 0, behavior: force ? 'instant' : 'smooth' });
		}
	}
}
