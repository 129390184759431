var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-block"},_vm._l((this.arResult),function(project,i){return _c('div',{key:i,staticClass:"project",class:[_vm.getWidthOrder(i),{ 'show-border': _vm.elementVisibleScroll['border-bottom' + i] && _vm.elementVisibleScroll['border-bottom' + i].visible}],attrs:{"showScroll":'border-bottom' + i}},[(project)?_c('base-button',{class:[{ 'show-link-arrow': _vm.elementVisibleScroll['toProject' + i] && _vm.elementVisibleScroll['toProject' + i].visible}],attrs:{"text":project.arFields.NAME,"link":project.arFields.DETAIL_PAGE_URL,"typeBtn":'link-arrow-right',"showScroll":'toProject' + i}}):_vm._e(),_c('div',{staticClass:"info"},[_c('div',{staticClass:"specifications",class:[
                     {'work-spec': project.arProperty.YEAR_WORK},
                     {'specifications-show-move-top': _vm.elementVisibleScroll['specifications' + i] && _vm.elementVisibleScroll['specifications' + i].visible}
                 ],attrs:{"showScroll":'specifications' + i}},[(project.arProperty.AREA_PROJECT)?_c('p',[_vm._v(" "+_vm._s(project.arProperty.AREA_PROJECT.VALUE)+" м"),_c('sup',[_vm._v("2")])]):(project.arProperty.AREA_WORK && project.arProperty.AREA_WORK.VALUE !== '')?_c('p',[_vm._v(" "+_vm._s(project.arProperty.AREA_WORK.VALUE)+" м"),_c('sup',[_vm._v("2")])]):_vm._e(),(project.arProperty.ROOM_PROJECT)?_c('span',[_vm._v(_vm._s(project.arProperty.ROOM_PROJECT.VALUE)+" "+_vm._s(_vm.roomCount(project.arProperty.ROOM_PROJECT.VALUE)))]):(project.arProperty.YEAR_WORK)?_c('span',[_vm._v(_vm._s(project.arProperty.YEAR_WORK.VALUE))]):_vm._e()]),(project.arProperty.PRICE_BUILDING?.VALUE)?_c('div',{staticClass:"price-build",class:[ {'specifications-show-move-top': _vm.elementVisibleScroll['priceBuild' + i] && _vm.elementVisibleScroll['priceBuild' + i].visible} ],attrs:{"showScroll":'priceBuild' + i}},[_vm._v(_vm._s(project.arProperty.PRICE_BUILDING.VALUE)+" ₽")]):_vm._e()]),(project.arFields.PREVIEW_PICTURE_INFO?.SRC)?_c('div',{staticClass:"project-img",class:[
                 {'show-img-project': _vm.elementVisibleScroll['projectImg' + i] && _vm.elementVisibleScroll['projectImg' + i].visible && project.arFields.PREVIEW_PICTURE_INFO.LOADED},
                 {'watermark': project.arFields.PREVIEW_PICTURE_INFO.WATERMARK},
             ],style:(!project.arFields.PREVIEW_PICTURE_INFO.LOADED ? 'opacity: 1 !important;': ''),attrs:{"showScroll":'projectImg' + i},on:{"click":function($event){return _vm.triggerLink()}}},[_c('img',{attrs:{"src":_vm.$hostname + project.arFields.PREVIEW_PICTURE_INFO.SRC,"width":project.arFields.PREVIEW_PICTURE_INFO.WIDTH,"height":project.arFields.PREVIEW_PICTURE_INFO.HEIGHT},on:{"load":function($event){[
                     _vm.backWatermark(i, project.arFields.PREVIEW_PICTURE_INFO),
                     project.arFields.PREVIEW_PICTURE_INFO.LOADED = true,
                     //$emit('imgLoaded')
                 ]}}}),_c('div',{staticClass:"wrap-backWatermark"},[(_vm.styleBackWatermark[i])?_c('div',{staticClass:"backWatermark",style:(_vm.styleBackWatermark[i])}):_vm._e()])]):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }