<template>
  <div class="comparison">

    <div class="swiper-table comparison-section" :class="[{'slide-effect': scrollTable.activeSlideEffect}, {'single-project' :arResult.length === 1}]">

      <separator :show="true"/>

      <h2 :class="['comparison-section-title', {moveTop:elementVisibleScroll['comparisonTitle'] && elementVisibleScroll.comparisonTitle.visible}]"
          showScroll="comparisonTitle">
        Сравнение<br>комплектаций
      </h2>

      <div class="nav-btn-table" showScroll="navBtn" :class="[{ 'animation-show-nav-btn': elementVisibleScroll.navBtn.visible}]">
        <div class="arrow-left" @click="slideTable(-1)" :class="[{'disable': scrollTable.leftArrowDisable}]">
          <svg width="50" height="28" viewBox="0 0 50 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50 12.336H6.40079L16.4317 2.35308L14.0673 0L0 14L14.0673 28L16.4317 25.6469L6.40058 15.6638H50V12.336Z" fill="#383838"/>
          </svg>
        </div>
        <div class="arrow-right" @click="slideTable(1)" :class="[{'disable': scrollTable.rightArrowDisable}]">
          <svg width="50" height="28" viewBox="0 0 50 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12.336H43.5992L33.5683 2.35308L35.9327 0L50 14L35.9327 28L33.5683 25.6469L43.5994 15.6638H0V12.336Z" fill="#383838"/>
          </svg>
        </div>
      </div>

      <div class="table-wrap checkboxWrap"
           @mousedown="startScrollTable()"
           @mousemove="horizontalScrollTable()"
           @mouseup="endScrollTable()"
           @touchstart="startScrollTable()"
           @touchmove="horizontalScrollTable()"
           @touchend="endScrollTable()"
           :style="scrollTable.styleContent"
      >
        <div class="row" showScroll="rowTable" :class="[{ 'opacity-animate': elementVisibleScroll.rowTable.visible}]" :style="scrollTable.widthBorderValue">
          <div class="col-xxl-5 col-md-6 fixed-col" :style="scrollTable.styleFixedCol">
            <p showScroll="priceTextMaterial" :class="[{ 'moveTop': elementVisibleScroll.priceTextMaterial.visible}]">
              цена
            </p>
          </div>
          <div class="value-col" v-for="(item, i) in configurations" :key="i" :class="[{'col-6': i !== configurations.length - 1}, {'col-xxl-7 col-6' : i === configurations.length - 1}]">
            <div class="price-item" showScroll="comparisonPrice" :class="[{ 'moveTop': elementVisibleScroll.comparisonPrice.visible}]">
              <p class="type-item">{{item.name}}</p>
              <span class="old" :class="item.basePrice !== item.currentPrice ? 'line-through' : ''">
                {{ item.basePrice !== item.currentPrice ? item.basePrice.toLocaleString("ru") + " ₽" : "" }}&nbsp;
              </span>
              <span v-if="item.currentPrice">
                {{ item.currentPrice.toLocaleString("ru") }} ₽
              </span>
            </div>
          </div>
        </div>
        
        <div class="row" v-for="(materialType, materialIndex) in materialTypes" :key="materialType.title" :style="scrollTable.widthBorderValue">
          <div class="col-xxl-5 col-md-6 fixed-col" :style="scrollTable.styleFixedCol">
            <p :showScroll="'rowMaterialTitle' + materialIndex" :class="[{ 'moveTop': elementVisibleScroll['rowMaterialTitle' + materialIndex] && elementVisibleScroll['rowMaterialTitle' + materialIndex].visible}]">
              {{ materialType.title }}
            </p>
          </div>
          <div v-for="(config, i) in configurations" :key="i" class="value-col" :class="[{'col-6': i !== configurations.length - 1}, {'col-xxl-7 col-6' : i === configurations.length - 1}]">
            <div class="material-block" :ref="`materialBlock${i}${materialIndex}`"
                 :class="{dark:config.selectedMaterials[materialType.title].price !== 0}">
              <div class="material-list" @click.prevent.stop="toggleOpen(i, materialType.title)"
                   :class="{ 'open-list': keepOpen[i] && keepOpen[i][materialType.title], 'toggle-list':openConfig === i && openType === materialType.title}">
                <div class="img-material">
                  <img :src=" materialsImageConfig[config.selectedMaterials[materialType.title].material]"/>
                </div>
                <div class="wrap-material">
                  <div
                      class="name-material moveTop"
                      showScroll="comparsionMaterial"
                  >
                    {{ config.selectedMaterials[materialType.title].subtitle }}
                  </div>
                  <div class="list-overflow-wrap" :style="{
                          height: openConfig === i && openType === materialType.title ? listHeight : '',
                          opacity: openConfig === i && openType === materialType.title ? 1 : 0,
                        }"
                  >
                    <div class="list">
                      <p v-for="option in materialType.properties" :key="option.type" @click.prevent="selectMaterial(i,materialType.title,option)">
                        {{ option.subtitle }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="price-material">
                  +{{ config.selectedMaterials[ materialType.title].price.toLocaleString("ru")}}₽
                </div>
                <div class="plus-icon" @click.prevent.stop="toggleOpen(i, materialType.title)" />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div v-for="(checkboxItem, checkboxIndex) in checkboxTable" :key="checkboxIndex" class="checkboxWrap">

        <h3>{{ checkboxItem.title }}</h3>
        <div class="table-wrap"
             @mousedown="startScrollTable()"
             @mousemove="horizontalScrollTable()"
             @mouseup="endScrollTable()"
             @touchstart="startScrollTable()"
             @touchmove="horizontalScrollTable()"
             @touchend="endScrollTable()"
             :style="scrollTable.styleContent"
        >

          <div class="row" v-for="(item, itemIndex) in checkboxItem.items" :key="itemIndex" :style="scrollTable.widthBorderValue">
            <div class="col-xxl-5 col-md-6 fixed-col" :style="scrollTable.styleFixedCol">
              <p :showScroll="'rowСheckboxTitle' + itemIndex + checkboxIndex" :class="[{ 'moveTop': elementVisibleScroll['rowСheckboxTitle' + itemIndex + checkboxIndex] && elementVisibleScroll['rowСheckboxTitle' + itemIndex + checkboxIndex].visible}]">
                {{ item.subtitle }}
                <DropdownText v-if="item.prompt" :text="item.prompt"/>
              </p>
            </div>

            <div class="value-col col-6">
              <template v-if="item.first === 'FACADE_PHOTO'">
                <img :src="decorationImage" />
              </template>
              <template v-else>
                <span v-if="item.first === 'PLUS'">
                  <icon-base width="24" height="20" icon-name="yes-icon"><yes-icon/></icon-base>
                </span>
                <span v-else-if="item.first === 'MINUS'">
                  <icon-base width="24" height="18" icon-name="no-icon"><no-icon/></icon-base>
                </span>
              </template>
            </div>
            <div class="value-col col-6">
              <template v-if="item.second === 'FACADE_PHOTO'">
                <img :src="decorationImage" />
              </template>
              <template v-else>
                <span v-if="item.second === 'PLUS'">
                  <icon-base width="24" height="20" icon-name="yes-icon"><yes-icon/></icon-base>
                </span>
                <span v-else-if="item.second === 'MINUS'">
                  <icon-base width="24" height="18" icon-name="no-icon"><no-icon/></icon-base>
                </span>
              </template>
            </div>
            <div class="value-col col-xxl-7 col-6">
              <template v-if="item.third === 'FACADE_PHOTO'">
                <img :src="decorationImage" />
              </template>
              <template v-else>
                <span v-if="item.third === 'PLUS'">
                  <icon-base width="24" height="20" icon-name="yes-icon"><yes-icon/></icon-base>
                </span>
                <span v-else-if="item.third === 'MINUS'">
                  <icon-base width="24" height="18" icon-name="no-icon"><no-icon/></icon-base>
                </span>
              </template>
            </div>
          </div>

        </div>
      </div>
    
    </div>

    <div class="comparison-dop comparison-section">
      <div class="comparison-section-head">
        <separator :show="isLinesOpen" />
        <h2 class="comparison-section-title" :class="[ { moveTop: elementVisibleScroll.comparsionDopUsligi.visible }]" showScroll="comparsionDopUsligi">
          Дополнительные<br>услуги
        </h2>
      </div>
      <div v-for="(item, index) in arResult.arProperty.ADDITIONAL_SERVICES_TABLE.VALUE"
           class="row"
           :key="index"
           :showScroll="'comparisonTableSubrow' + index"
           :class="[
                {
                  moveTop:
                    elementVisibleScroll['comparisonTableSubrow' + index] &&
                    elementVisibleScroll['comparisonTableSubrow' + index].visible,
                },
              ]">
        <p class="offset-xxl-5 offset-md-4 col-md-6">
          {{ item.SUB_VALUES.ADDITIONAL_SERVICES_KEY.VALUE }} <br>
          <span v-if="item.SUB_VALUES.ADDITIONAL_SERVICES_KEY_DESC && item.SUB_VALUES.ADDITIONAL_SERVICES_KEY_DESC.VALUE">
            
             {{ item.SUB_VALUES.ADDITIONAL_SERVICES_KEY_DESC.VALUE }} 
          </span>
        </p>
        <span class="col-md-12">{{ item.SUB_VALUES.ADDITIONAL_SERVICES_VALUE.VALUE }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Separator from "../../../../layout/Separator.vue";
import DropdownText from "../../../../layout/DropdownText.vue";
import IconBase from "@/components/IconBase.vue";
import YesIcon from "@/components/icons/YesIcon.vue";
import NoIcon from "@/components/icons/NoIcon.vue"; // adjust the path as necessary

export default {
  name: "Comparison",
  components: {
    NoIcon,
    YesIcon,
    IconBase,
    Separator,
    DropdownText,
  },
  props: {
    arResult: Object,
    constructorItems: Array,
    elementVisibleScroll: Object,
    animationCallback: Function,
    checkboxTable: Array,
    decorationImage: String,
  },
  data() {
    return {
      configurations: [],
      openConfig: null,
      openType: null,
      listWidth: "",
      listHeight: "",
      keepOpen: {},
      materialsImageConfig: {
        TAPES: require("@/assets/icon/material-lentochniy.jpg"),
        PILES: require("@/assets/icon/material-svai.jpg"),
        AERED_CONCRETE: require("@/assets/icon/material-gazoblock.jpg"),
        BRICK: require("@/assets/icon/material-kirpich.jpg"),
      },
      materialTypes: [],
      currentInterval: {},
      isLinesOpen: false,
      scrollTable:{
        widthContent: 0,           // полная ширина таблицы
        borderRight: 0,           // правая граница таблицы (максимальное смещение влево)
        positionContent: 0,      // позиция таблицы
        newPositionContent: 0,  // Новая позиция таблицы
        clickCoordX: 0,           // Координаты курсора при клике
        moveCoordX: 0,           // Координаты курсора при движении
        styleContent:'',          // стиль для таблицы
        styleFixedCol: '',       // стиль для фиксированных заголовков
        activeEventMove: false,   // флаг для активация события "движения мышки"
        activeEventClick: true,  // флаг для активация события "клик мышки"
        widthSlide: 0,            // ширина 1 колонки с информацией
        widthFixedCol: 0,        // ширина 1 колонки с заголовками
        leftArrowDisable: true,   // флаг отключения навигации
        rightArrowDisable: false,// флаг отключения навигации
        activeSlideEffect: false, // флаг включения эффекта слайдера
        widthBorderValue: '',    // стиль для продолжения таблицы
        activeDiffElement: false,
      },
    };
  },
  watch: {
    openConfig() {
      this.updateKeepOpen();
    },
    openType() {
      this.updateKeepOpen();
    },
    'scrollTable.styleContent': function () {

      if(this.scrollTable.newPositionContent + 15 > 0){   // если таблица пытается выйит за левый край
        this.scrollTable.leftArrowDisable = true       // отключаем левую стреочку
      } else {
        this.scrollTable.leftArrowDisable = false
      }

      if(this.scrollTable.borderRight > this.scrollTable.newPositionContent - 15){ // если таблица пытается выйит за правый край
        this.scrollTable.rightArrowDisable = true                               // отключаем правую стреочку
      }   else {
        this.scrollTable.rightArrowDisable = false
      }
    }
  },
  methods: {
    toggleOpen(configIndex, materialType) {
      event.preventDefault();
      event.stopPropagation();

      let materialList = event.target.offsetParent;
      const list = materialList.querySelector(".list");

      if (this.openConfig === configIndex && this.openType === materialType) {
        this.listWidth = "";
        this.listHeight = "";

        this.openConfig = null;
        this.openType = null;
      } else {
        this.listWidth = list.offsetWidth + "px";
        this.listHeight = list.offsetHeight + "px"; // добавляем отступ
        this.openConfig = configIndex;
        this.openType = materialType;
      }
    },
    selectMaterial(configIndex, materialType, option) {
      const config = this.configurations[configIndex];
      const oldOption = config.selectedMaterials[materialType];
      const priceDifference = option.price - oldOption.price;

      config.currentPrice += priceDifference;
      this.$set(config.selectedMaterials, materialType, option);
      setTimeout(() => {
        this.openConfig = null;
        this.openType = null;
      }, 400);
    },
    updateKeepOpen() {
      this.materialTypes.forEach((materialType) => {
        this.configurations.forEach((config, configIndex) => {
          if (!this.keepOpen[configIndex]) {
            this.$set(this.keepOpen, configIndex, {});
          }
          if (
            this.openConfig === configIndex &&
            this.openType === materialType.title
          ) {
            this.$set(this.keepOpen[configIndex], materialType.title, true);
          } else {
            setTimeout(() => {
              this.$set(this.keepOpen[configIndex], materialType.title, false);
            }, 300);
          }
        });
      });
      this.animationCallback();
    },
    formatInNumeric(value) {
      return value.trim() === ""
        ? undefined
        : parseInt(value.replace(/\s/g, ""), 10);
    },
    initializeConfigurations() {
      const basePrice = this.formatInNumeric(this.arResult.arProperty.PRICE_BUILDING.VALUE);

      this.constructorItems.forEach((constructorItem) => {
        if (constructorItem.title === "комплектация") {
          constructorItem.properties.forEach((item) => {
            const selectedMaterials = {};
            this.constructorItems
              .filter((i) => i.title !== "комплектация")
              .forEach((materialType) => {
                selectedMaterials[materialType.title] =
                  materialType.properties[0];
              });
            console.log(selectedMaterials);
            this.configurations.push({
              name: item.subtitle,
              basePrice: basePrice + item.price,
              currentPrice: basePrice + item.price,
              selectedMaterials: selectedMaterials,
            });
          });
        }
      });
      this.materialTypes = this.constructorItems.filter(
        (item) => item.title !== "комплектация"
      );
    },

    slideTable(vector){
      this.propertyWrap();

      let moveRange = this.scrollTable.widthSlide * vector;

      this.scrollTable.newPositionContent = (this.scrollTable.positionContent - moveRange);
      if(this.scrollTable.newPositionContent > 0){
        this.scrollTable.newPositionContent = 0;
      } else if(this.scrollTable.borderRight - (this.scrollTable.widthSlide / 5) > this.scrollTable.newPositionContent){
        this.scrollTable.newPositionContent = this.scrollTable.borderRight - (this.scrollTable.widthSlide / 5);
      }

      this.scrollTable.styleContent = 'left: ' + this.scrollTable.newPositionContent + 'px; transition: left 0.6s cubic-bezier(0, 0, 0.26, 1)';
      this.scrollTable.styleFixedCol = 'left: ' + this.scrollTable.newPositionContent * (-1) + 'px; transition: left 0.6s cubic-bezier(0, 0, 0.26, 1)';
    },
    startScrollTable(){
      if (event.target.closest('.plus-icon') || event.target.closest('.list-overflow-wrap')) {
        event.stopPropagation();
        return;
      }

      if(this.scrollTable.activeEventClick === true && this.scrollTable.activeSlideEffect === true){ // если прошла анимация с предыдущего скролла
        this.propertyWrap();                       // обновляем информацию о таблице
        if(event.pageX){                          // если мышка
          this.scrollTable.clickCoordX = event.pageX;
        } else {                                // если палец
          this.scrollTable.clickCoordX = event.changedTouches[0].pageX;
        }
        this.scrollTable.activeEventMove = true;    // активируем событие Движение курсора
      }
    },
    horizontalScrollTable(){
      if(this.scrollTable.activeEventMove === true){       // если был клик
        if(event.pageX){                                // если мышка
          this.scrollTable.moveCoordX = event.pageX;
        } else {                                       // если палец
          this.scrollTable.moveCoordX = event.changedTouches[0].pageX
        }

        this.scrollTable.newPositionContent = this.scrollTable.moveCoordX - this.scrollTable.clickCoordX + this.scrollTable.positionContent; // путь курсора + старая позиция таблицы
        if(this.scrollTable.newPositionContent > 0){  // если таблица пытается выйит за левый край
          this.scrollTable.newPositionContent = 0;
        } else if(this.scrollTable.borderRight - (this.scrollTable.widthSlide / 5) > this.scrollTable.newPositionContent){ // если таблица пытается выйит за правый край + эффект пружинки
          this.scrollTable.newPositionContent = this.scrollTable.borderRight - (this.scrollTable.widthSlide / 5);
        }
        this.scrollTable.styleContent = 'left: ' + this.scrollTable.newPositionContent + 'px';
        this.scrollTable.styleFixedCol = 'left: ' + this.scrollTable.newPositionContent * (-1) + 'px';
      }
    },
    endScrollTable(){
      //console.log()
      this.scrollTable.activeEventClick = false;
      this.scrollTable.activeEventMove = false;
      let deltaCoordX = this.scrollTable.clickCoordX - this.scrollTable.moveCoordX; // путь курсора
      let moveRange = 0;
      let vector = -1;
      let multiplierWidthSlide = 1;    // кол-во пролистанных колонок курсором
      if(deltaCoordX > 0){            // движение курсора влево
        vector = 1;
      } else if(deltaCoordX < 0) {  // движение курсора вправо
        vector = -1;
      }

      multiplierWidthSlide += Math.floor(Math.abs(deltaCoordX) / this.scrollTable.widthSlide);
      moveRange = Math.abs(((this.scrollTable.widthSlide * multiplierWidthSlide) - Math.abs(deltaCoordX))) * vector; // путь для таблицы

      this.scrollTable.newPositionContent = (this.scrollTable.newPositionContent - moveRange);    // новая позиция таблицы
      if(this.scrollTable.newPositionContent > 0){
        this.scrollTable.newPositionContent = 0;
      } else if(this.scrollTable.borderRight > this.scrollTable.newPositionContent){
        this.scrollTable.newPositionContent = this.scrollTable.borderRight;
      }

      this.scrollTable.styleContent = 'left: ' + this.scrollTable.newPositionContent + 'px; transition: left 0.6s cubic-bezier(0, 0, 0.26, 1)';
      this.scrollTable.styleFixedCol = 'left: ' + this.scrollTable.newPositionContent * (-1) + 'px; transition: left 0.6s cubic-bezier(0, 0, 0.26, 1)';

      this.scrollTable.clickCoordX = 0;
      this.scrollTable.moveCoordX = 0;

      window.setTimeout( () => this.scrollTable.activeEventClick = true, 300);
    },
    propertyWrap(){
      let paddingApp = 20;    // отступ контейнера
      if(window.innerWidth <= 767){
        paddingApp = 10;
      }
      this.checkWidthContent();

      this.scrollTable.widthSlide = document.querySelectorAll('.table-wrap .value-col')[0].scrollWidth;
      let modalWidth = window.innerWidth * 0.88;
      let maxSlidePerScreen = Math.floor((modalWidth - this.scrollTable.widthFixedCol) / this.scrollTable.widthSlide);

      this.scrollTable.borderRight = window.innerWidth - this.scrollTable.widthContent - (window.innerWidth - (this.scrollTable.widthSlide * maxSlidePerScreen) - this.scrollTable.widthFixedCol);
      this.scrollTable.positionContent = document.querySelectorAll('.table-wrap')[0].offsetLeft - paddingApp;
    },
    checkWidthContent(){
      this.scrollTable.widthFixedCol = document.querySelectorAll('.table-wrap .fixed-col')[0].scrollWidth;
      if(window.innerWidth <= 767){
        this.scrollTable.widthFixedCol = 0;
      }

      let firstRow = document.querySelectorAll('.table-wrap .row')[0];    // берем 1 строку в таблице
      let sumWidthSlide = 0;
      firstRow.querySelectorAll('.value-col').forEach(function(item) { // ищем в строк все колонки с информацией
        sumWidthSlide += item.offsetWidth;                                    // получаем ширину всех колонк с информацией
      });

      this.scrollTable.widthContent = sumWidthSlide + this.scrollTable.widthFixedCol;

      if(this.scrollTable.widthContent > window.innerWidth){  // если ширина таблицы выходдит за рамки браузера
        this.scrollTable.activeSlideEffect = true;         // включаем эффект слайдера
        this.scrollTable.widthBorderValue = 'width: ' + (this.scrollTable.widthContent + this.scrollTable.widthSlide) + 'px;'; // продлеваем бордеры

      } else if (this.scrollTable.widthContent + 40 < window.innerWidth){                                              // если ширина таблицы в рамках браузера
        this.scrollTable.activeSlideEffect = false;      // отключаем эффект слайдера
        this.scrollTable.widthBorderValue = '';         // удаляем стиль ширины для бордера
      }
    },
    resizeWindow(){
      this.scrollTable.styleContent = 'left: ' + 0 + 'px; transition: left 0.6s cubic-bezier(0, 0, 0.26, 1)';
      this.scrollTable.styleFixedCol = 'left: ' + 0 + 'px; transition: left 0.6s cubic-bezier(0, 0, 0.26, 1)';
      this.checkWidthContent();
    },
  },
  created() {
    window.addEventListener('resize', this.resizeWindow);
    this.initializeConfigurations();
    this.currentInterval = setInterval(() => {
      this.animationCallback();
    }, 300);
    setTimeout(() => {
      this.isLinesOpen = true;
      this.checkWidthContent();
    }, 250);
  },
  updated() {
    clearInterval(this.currentInterval);
    this.currentInterval = setInterval(() => {
      this.animationCallback();
    }, 300);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeWindow);
  }
};
</script>

<style scoped></style>
