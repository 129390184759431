<template>
    <transition name="pop-up-form">
        <div class="modal-side" v-if="show" @click="closeForm">
            <div class="modal-side-wrapper" @click.stop>
                <div class="modal-side-content">
                    <slot></slot>
                </div>
                <div class="line-close" @click="closeForm" />
            </div>
        </div>
    </transition>
</template>

<script>
import { scrollStyle } from "../../jsFunction/hiddenScroll";
export default {
    name: "ModalSide",
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        show(newVal) {
            if (newVal) {
                scrollStyle.methods.hidden(false,true);
            } else {
                scrollStyle.methods.show();
            }
        }
    },
    methods: {
        closeForm() {
            scrollStyle.methods.show();
            this.$emit('update:show', false);
        },
    },
}
</script>

<style scoped></style>