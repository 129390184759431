<template>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9853 13.5009H2.01472L2.01472 10.5009H21.9853L21.9853 13.5009Z" fill="#383838"/>
</template>

<script>
    export default {
        name: "NoIcon"
    }
</script>

<style scoped>

</style>