<template>
    <div class="price-cell-menu" :class="{ 'open': toggleAccordion }">
        <div class="title-wrap-menu">
            <h3>Цены</h3>
            <icon-base :width="14" :height="12" :class="'triangle-toggle-btn'"><triangle-down /></icon-base>
        </div>

        <div class="accordion-body" :style="styleAccordion">
            <div class="section-list">
                <div class="link-list">
                    <a class="hide-line" :class="[{ 'active': '/mortgage' === normalizePath($route.path) }]" :href="'/mortgage/'" @click="changePage('/mortgage/')">
                        ипотека
                    </a>
                    <a class="hide-line" :class="[{ 'active': '/price' === normalizePath($route.path) }]" :href="'/price/'" @click="changePage('/price/')">
                        стоимость проектов
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { getClBlockElement } from "../../../jsFunction/getCIBlockElement";
import IconBase from "../../IconBase";
import TriangleDown from "../../icons/TriangleDown";

export default {
    name: "MenuCellPrice",
    components: { TriangleDown, IconBase },
    props: ['toggleAccordion'],
    data() {
        return {
            filterArea: [],
            countAll: '',
            styleAccordion: '',
            // arSort: {
            //     'SORT': 'ASC'
            // },
            // arFilter: {
            //     'IBLOCK_ID': 10, 'ACTIVE': 'Y',
            // },
            // arSelect: [
            //     'NAME', 'CODE', 'PREVIEW_PICTURE', 'DETAIL_PAGE_URL', 'GET_FILTER'
            // ],
            // arNuvParam: {
            //     'iNumPage': 1, 'nPageSize': 1, 'realPageSize': 1,
            // },
        }
    },
    watch: {
        toggleAccordion: function (value) {
            if (value) {
                this.openAccordion();
            } else {
                this.closeAccordion();
            }
        }
    },
    methods: {
        changePage(path) {
            event.preventDefault();
            if (path !== this.$route.path && path !== '') {
                this.$router.push(path);
                this.$emit('closeRightMenu');
            }
        },
        openAccordion() {
            let heightAccordionBody = document.querySelectorAll('.price-cell-menu .section-list')[0].clientHeight;
            this.styleAccordion = 'height:' + heightAccordionBody + 'px;';
        },
        closeAccordion() {
            this.styleAccordion = '';
        },
        normalizePath(path) {
            return path.replace(/\/+$/, '');
        }
    },
    created() {
        // getClBlockElement.methods.GetList(this.arSort, this.arFilter, false, this.arNuvParam, this.arSelect).then((data) => {
        //     this.countAll = data.infoBlock.ALL_COUNT_ITEM;
        // });
    }
}
</script>

<style scoped></style>