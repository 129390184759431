<template>
    <div class="mortgage-price" id="mortgage-price">
        <separator :show="true" />

        <div class="row">
            <h2 class="calculator-title col-xxl-9 col-xl-10 " showScroll="titleCalculator"
                :class="[{ 'moveTop': elementVisibleScroll.titleCalculator.visible }]">
                Рассчитать ипотеку
            </h2>
            
            <div class="calculator-right col-xxl-11 offset-xxl-3 offset-xl-2 col-xl-11">
                <div class="calculator-desc"
                    showScroll="descCalculator" :class="[{ 'moveTop': elementVisibleScroll.descCalculator.visible }]">
                    наша компания аккредитована во&nbsp;всех банках&nbsp;РФ
                </div>
                <div class="calculator-banks">
                    <div class="calculator-banks__item" v-for="(item, i) in arResult.arProperty.BANKS_LIST.FILE_INFO " :key="i"
                        :style="{zIndex: arResult.arProperty.BANKS_LIST.FILE_INFO.length - i}"
                        :showScroll="'banks' + i"
                        :class="[{ 'moveTop': elementVisibleScroll['banks' + i] && elementVisibleScroll['banks' + i].visible }]"
                        >
                        <img class="calculator-banks__item-img" :src="$hostname + item.SRC">
                    </div>
                    <div class="calculator-banks__item calculator-banks__item--num"
                        :showScroll="'banks' + 100"
                        :class="[{ 'moveTop': elementVisibleScroll['banks' + 100] && elementVisibleScroll['banks' + 100].visible }]"
                    >+{{ arResult.arProperty.BANKS_NUM.VALUE }}</div>
                </div>
            </div>
            
            <div class="input-col col-xxl-9 col-xl-10">
                <div class="input-calculator"
                    :showScroll="'inputCalculator' + 0"
                    :class="[{ 'moveTop': elementVisibleScroll['inputCalculator' + 0] && elementVisibleScroll['inputCalculator' + 0].visible }]"
                >
                    <div class="input-calculator__top">
                        <div class="title">сумма ипотеки</div>
                        <span class="calculator-temp-value">{{ Number(initialPayment).toLocaleString('ru') }} ₽</span>
                    </div>
                    <input v-model="initialPayment" type="range" min="100000" max="30000000" step="10000"
                        class="input-calculator__progress" style="--min: 100000; --max: 30000000;"
                        @input="changeProgress($event)" />
                    <div class="input-calculator__bottom">
                        <span>100 тыс. ₽</span>
                        <span>30 млн. ₽</span>
                    </div>
                </div>
                <div class="input-calculator"
                    :showScroll="'inputCalculator' + 1"
                    :class="[{ 'moveTop': elementVisibleScroll['inputCalculator' + 1] && elementVisibleScroll['inputCalculator' + 1].visible }]"
                >
                    <div class="input-calculator__top">
                        <div class="title">срок ипотеки</div>
                        <span class="calculator-temp-value">{{ period }} {{ periodText }}</span>
                    </div>
                    <input v-model="period" type="range" min="5" max="30" class="input-calculator__progress" step="1"
                        style="--min: 5; --max: 30;" @input="changeProgress($event)" />
                    <div class="input-calculator__bottom">
                        <span>5 лет</span>
                        <span>30 лет</span>
                    </div>
                </div>
                <div class="input-calculator"
                    :showScroll="'inputCalculator' + 2"
                    :class="[{ 'moveTop': elementVisibleScroll['inputCalculator' + 2] && elementVisibleScroll['inputCalculator' + 2].visible }]"
                >
                    <div class="input-calculator__top">
                        <div class="title">ставка</div>
                        <span class="calculator-temp-value">{{ stake }}%</span>
                    </div>
                    <input v-model="stake" type="range" min="0.1" max="15" step="0.1" class="input-calculator__progress"
                        style="--min: 0.1; --max: 15;" @input="changeProgress($event)" />
                    <div class="input-calculator__bottom">
                        <span>0,1%</span>
                        <span>15%</span>
                    </div>
                </div>
            </div>

            <div class="col-xxl-11 col-xl-11 offset-xxl-3 offset-xl-2 result-price">
                <p class="month-payment-text" showScroll="monthPayment"
                    :class="[{ 'moveTop': elementVisibleScroll.monthPayment.visible }]">ежемесячный платеж*</p>
                <div class="month-payment-price" showScroll="monthPaymentPrice"
                    :class="[{ 'moveTop': elementVisibleScroll.monthPaymentPrice.visible }]">
                    {{ Math.floor(Number(resultPrice) / 12) }} ₽*
                </div>
                <p class="text-result-price" showScroll="textResultPrice"
                    :class="[{ 'moveTop': elementVisibleScroll.textResultPrice.visible }]">
                    **Расчет предварительный, по&nbsp;средней ставке. Точная ставка и&nbsp;сумма ипотеки будут
                    определены при&nbsp;оформлении договора. Банк вправе отказать в&nbsp;выдаче ипотеки
                    без&nbsp;объяснения причин.
                </p>
                <div class="mortage-btns">
                    <base-button :text="'консультация'" :link="'javascript:void(0);'" :typeBtn="'base-btn revers'"
                        showScroll="btnConsul2" class="mortgage-consult" :class="[{ 'show-button': elementVisibleScroll.btnConsul2.visible }]"
                        @clickBtn="openCallBackForm(5, 'консультация')" />
                    <base-button :text="'подробнее об ипотеке'" :link="'/mortgage/'" :typeBtn="'base-btn '" showScroll="mortgageMore"
                        class="mortgage-more"  :class="[{ 'show-button': elementVisibleScroll.mortgageMore.visible }]"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Separator from "../Separator"
import BaseButton from "../BaseButton";
import { getClBlockElement } from "../../../jsFunction/getCIBlockElement";

export default {
    name: "MortgageCalculator",
    components: { BaseButton, Separator },
    mixins: {getClBlockElement},
    props: {
        rangeParams: [Object, Array],
        elementVisibleScroll: Object,
        currentPrice: {
            type: Number,
            default: 1440000
        }
    },
    data() {
        return {
            arResult: {
                arProperty: {
                    BANKS_LIST: [],
                    BANKS_NUM: [],
                }
            },
            arSort: {
                'SORT': 'ASC'
            },
            arFilter: {
                'IBLOCK_ID': 22, 'ACTIVE': 'Y',
            },
            arSelect: [
                'NAME', 'CODE', 'PREVIEW_PICTURE', 'DETAIL_PAGE_URL',
            ],
            arNuvParam: {},
            showAfterIntro: true,
            resultPrice: 0,
            initialPayment: this.currentPrice,
            // initialPayment: 1440000,
            period: 14,
            periodText: 'лет',
            stake: 6,
        }
    },
    computed: {
        bitrixStore() {
            return this.$store.getters.cacheData;
        }
    },
    watch: {
        resultPrice: function () {
            this.calculationFormula();
        },
        currentPrice: function (newVal) {
            this.initialPayment = newVal;
        },
        initialPayment: function () {
            this.calculationFormula();
        }
    },
    methods: {
        yearText() {
            let txt, count = this.period % 100;
            count >= 5 && count <= 20 ? txt = 'лет' : count = count % 10, count == 1 ? txt = 'год' : (count >= 2 && count <= 4) ? txt = 'года' : txt = 'лет';
            this.periodText = txt;
        },
        changeProgress(event) {
            event.target.style.setProperty('--value', event.target.value);
            this.yearText(this.period);
            this.calculationFormula();
        },
        openCallBackForm(id, title) {
            let getOnlyFields = 'ALL',
                sort = 's_sort',
                order = 'asc',
                arFilter = {
                    'ACTIVE': 'Y'
                };
            this.$store.dispatch('receiveParamForm', {
                id: id, getOnlyFields: getOnlyFields, sort: sort, order: order, arFilter: arFilter, title: title
            });
        },
        // created(data) {
        //     console.log('created methods');
        //     this.arResult = data.ITEMS;
        //     this.calculationFormula();
        //     setTimeout(() => {
        //         this.$emit('created');
        //     }, 200)
        // },
        calculationFormula() {
            this.resultPrice = 0;
            let i = this.stake / 1200;
            let temp = Math.pow((1 + i), this.period);
            this.resultPrice = Math.round(this.initialPayment * ((i * temp) / (temp - 1)));
        }
    },
    created() {
        
        if (!this.bitrixStore[this.arFilter.IBLOCK_ID]) {
            getClBlockElement.methods.GetList(this.arSort, this.arFilter, false, this.arNuvParam, this.arSelect).then((data) => {
                this.arResult = data.ITEMS[0];
                this.$store.dispatch('receiveCacheData', { id: this.arFilter.IBLOCK_ID, cacheData: data })
            })
        } else {
            this.arResult = this.bitrixStore[this.arFilter.IBLOCK_ID].ITEMS[0];
        }
    },
    mounted() {
        for (let e of document.querySelectorAll('input[type="range"].input-calculator__progress')) {
            e.style.setProperty('--value', e.value);
            e.style.setProperty('--min', e.min == '' ? '0' : e.min);
            e.style.setProperty('--max', e.max == '' ? '100' : e.max);
            e.addEventListener('input', () => e.style.setProperty('--value', e.value));
        }
        this.calculationFormula();
    }


}
</script>

<style scoped></style>