import { render, staticRenderFns } from "./ProjectStandardDetailedPage.vue?vue&type=template&id=fe5027ce&scoped=true"
import script from "./ProjectStandardDetailedPage.vue?vue&type=script&lang=js"
export * from "./ProjectStandardDetailedPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe5027ce",
  null
  
)

export default component.exports