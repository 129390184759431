<template>
  <div
    class="project-detailed-page project-detailed-page--standard container"
    :class="[
      { stopAnimation: !getProgressAxios.statusResponseAxios && !changeCode },
      { 'change-screen': changeCode },
    ]"
    itemscope
    itemtype="http://schema.org/Product"
  >
    <div class="row preview-info">
      <h1
        itemprop="name"
        class="col-xl-13 col-md-123 col-17 order-1"
        showScroll="title"
        :class="[{ moveTop: elementVisibleScroll.title.visible }]"
      >
        {{ arResult.arFields.NAME }}
      </h1>

      <div
        class="col-btn col-xl-6 col-md-auto col-23 order-xl-2 order-6 offset-xl-0 offset-md-auto"
      >
        <base-button
          :text="'консультация'"
          :link="'javascript:void(0);'"
          :typeBtn="'base-btn'"
          showScroll="consultationBtn1"
          :class="[
            {
              'show-button':
                elementVisibleScroll.consultationBtn1.visible || fixedBtn,
            },
          ]"
          @clickBtn="openCallBackForm(2, 'консультация')"
        />
      </div>

      <div class="col-auto offset-auto order-xl-3 order-2 favourite-btn-block">
        <div
          class="favourite-btn"
          showScroll="like"
          :class="[
            { moveTop: elementVisibleScroll.title.visible },
            { selected: checkFavourite },
          ]"
          @click="setFavourite()"
        >
          <icon-base width="23" height="20" icon-name="favourite-icon"
            ><favourite-icon
          /></icon-base>
        </div>
      </div>

      <div
        class="col-xxl-3 col-xl-4 col-23 architectural-style order-xl-4 order-3"
        v-if="arResult.arProperty.ARCHITECTURAL_STYLE.VALUE"
        showScroll="architectural"
        :class="[{ moveTop: elementVisibleScroll.architectural.visible }]"
      >
        <span class="title-info">архитектурный стиль</span>
        <p>{{ arResult.arProperty.ARCHITECTURAL_STYLE.VALUE }}</p>
      </div>

      <div
        class="col-xxl-9 col-xl-6 col-md-12 col-23 home-decoration order-xl-4 order-md-7 order-8"
        v-if="arResult.arProperty.ARCHITECTURAL_STYLE.VALUE"
        showScroll="decoration"
        :class="[{ moveTop: elementVisibleScroll.decoration.visible }]"
      >
        <div>
          <span class="title-info">отделка дома</span>
          <p>{{ selectedDecoration.VALUE }}</p>
        </div>
        <div class="home-decoration-checks">
          <label
            v-for="(decoration, index) in decorations"
            :key="index"
            class="home-decoration-check"
            :class="{
              active:
                decoration.data.ITEMS[0].arProperty.DECORATION.VALUE_XML_ID ===
                selectedDecoration.VALUE_XML_ID,
            }"
          >
            <input
              type="radio"
              name="decoration"
              :checked="
                decoration.data.ITEMS[0].arProperty.DECORATION.VALUE_XML_ID ===
                selectedDecoration.VALUE_XML_ID
              "
              @change="updateDecoration(decoration)"
              class="visually-hidden"
            />
            <img
              class="home-decoration-check-img"
              :src="decoration.decoration.imageUrl"
            />
          </label>
        </div>
      </div>

      <div
        class="col-xxl-3 col-xl-4 col-md-6 col-auto area-project order-xl-5 order-md-5 order-7"
        v-if="arResult.arProperty.AREA_PROJECT.VALUE"
        showScroll="area"
        :class="[{ moveTop: elementVisibleScroll.area.visible }]"
      >
        <div>
          <span class="title-info">общая площадь</span>
          <p>{{ arResult.arProperty.AREA_PROJECT.VALUE }}М<span>2</span></p>
        </div>
      </div>
      <!-- NPS -->
      <div
        class="col-xxl-4 col-xl-4 col-md-11 col-23 price-mortgage order-xl-5 order-md-7 order-9"
        v-if="minimalMortage"
        showScroll="mortgage"
        :class="[{ moveTop: elementVisibleScroll.mortgage.visible }]"
      >
        <div class="col-inner">
          <span class="title-info">ипотека от</span>
          <p>{{ minimalMortage }} ₽ в мес.</p>
        </div>
      </div>

      <div
        class="col-xxl-4 col-xl-5 col-md-8 col-14 price-build order-xl-6 order-4"
        v-if="currentPrice"
        showScroll="priceBuild"
        :class="[{ moveTop: elementVisibleScroll.priceBuild.visible }]"
        itemprop="offers"
        itemscope
        itemtype="http://schema.org/Offer"
      >
        <span class="title-info">базовая цена строительства</span>
        <p itemprop="price">
          {{ currentPrice }} <b itemprop="priceCurrency">₽</b>
        </p>
      </div>
    </div>

    <swiper-detailed-projects
      :ar-slide="arSlidePicture"
      :element-visible-scroll="elementVisibleScroll"
      @click="openLightBox($event, 'arSlidePicture')"
      @slideChange="startAnimation"
    />

    <div class="project-about">
      <separator animation-delay="1000" :show="true" />
      <div class="project-about-head">
        <h2
          showScroll="specificationsTitle"
          :class="[
            { moveTop: elementVisibleScroll.specificationsTitle.visible },
          ]"
        >
          О проекте
        </h2>

        <base-button
          :text="'консультация'"
          :link="'javascript:void(0);'"
          :typeBtn="'base-btn '"
          showScroll="consultationBtn2"
          class="btn-mobile-hidden"
          :class="[
            { 'show-button': elementVisibleScroll.consultationBtn2.visible },
          ]"
          @clickBtn="openCallBackForm(2, 'консультация')"
        />
      </div>

      <div class="project-about-params">
        <div class="row">
          <div class="col-23 col-lg-8 col-xxl-7">
            <div class="price-block">
              <div
                class="price-build2"
                v-if="currentPrice"
                showScroll="priceBuild2"
                :class="[{ moveTop: elementVisibleScroll.priceBuild2.visible }]"
              >
                <span class="title-info">базовая цена</span>
                <p>{{ currentPrice }} ₽</p>
              </div>

              <button
                class="btn-configurations base-link"
                showScroll="configurations"
                @click="showModal = true"
                :class="[
                  { moveTop: elementVisibleScroll.configurations.visible },
                ]"
              >
                что входит в стоимость
              </button>
              
                <div class="mortgage">
                  <div
                    class="mortgage-list"
                    showScroll="mortgageRates"
                    :class="[
                      { moveTop: elementVisibleScroll.mortgageRates.visible },
                    ]"
                  >
                    <div class="mortgage-item-title">ипотека от</div>
                    <span class="mortgage-item-value"
                      >{{ minimalMortage }} ₽/мес</span
                    >
                    <template
                        v-for="(item, i) in arInfoBlock.arProperty
                          .INTEREST_RATES_LIST.VALUE"
                      >
                      <div v-if="item" :key="'div-' + i">{{ item }}</div>
                      <span
                        v-if="
                          arInfoBlock.arProperty.INTEREST_RATES_LIST.DESCRIPTION[
                              i
                            ]
                          "
                          :key="'span-' + i"
                      >{{
                          arInfoBlock.arProperty.INTEREST_RATES_LIST.DESCRIPTION[
                              i
                            ]
                          }}</span
                    >
                    </template>
                  </div>
                  <button
                    class="mortgage-calculate secondary-vert-button"
                    showScroll="calculateMortgage"
                    :class="[
                      { moveTop: elementVisibleScroll.calculateMortgage.visible },
                    ]"
                    @click="scrollToSection"
                  >
                    <span>рассчитать ипотеку</span>
                  </button>
              </div>
            </div>
          </div>
          <div class="col-23 col-lg-15 col-xxl-16">
            <div class="constructor">
              <div
                class="constructor-head"
                showScroll="constructorHead"
                :class="[
                  { moveTop: elementVisibleScroll.constructorHead.visible },
                ]"
              >
                <div class="constructor-title">Конструктор дома</div>
                <div class="constructor-desc">
                  в зависимости от выбора конфигураций, цена строительства может
                  измениться
                </div>
              </div>

              <div class="constructor-body">
                <div
                  class="constructor-section"
                  v-for="(
                    constructorItem, constructorIndex
                  ) in constructorItems"
                  :key="constructorItem.title"
                >
                  <separator :show="true" />
                  <div
                    class="constructor-section-head"
                    :showScroll="'packageHead' + constructorIndex"
                    :class="[
                      { moveTop:  elementVisibleScroll['packageHead' + constructorIndex] && elementVisibleScroll['packageHead' + constructorIndex].visible },
                    ]"
                  >
                    <div class="constructor-section-title">
                      {{ constructorItem.title }}
                    </div>
                    <button
                      class="btn-configurations base-link"
                      @click="showModal = true"
                      v-if="constructorItem.title === 'комплектация'"
                    >
                      что входит в стоимость
                    </button>
                  </div>
                  <div
                    class="variants"
                    :showScroll="'packageVariants' + constructorIndex"
                    :class="[
                      { moveTop: elementVisibleScroll['packageVariants' + constructorIndex] && elementVisibleScroll['packageVariants' + constructorIndex].visible },
                    ]"
                  >
                    <div
                      class="variant"
                      v-for="(
                        equipmentItem, equipmentIndex
                      ) in constructorItem.properties"
                      :key="equipmentItem.subtitle"
                    >
                      <label class="variant-label">
                        <input
                          class="variant-input visually-hidden"
                          type="radio"
                          :checked="equipmentIndex === 0"
                          :name="`constructor-item-${constructorIndex}`"
                          :value="equipmentItem.price"
                          @change="updatePrice"
                        />
                        <div class="variant-checkmark"></div>
                        <div class="variant-info">
                          <div class="variant-title">
                            {{ equipmentItem.subtitle }}
                          </div>
                          <div class="variant-subtitle">
                            {{ equipmentItem.description }}
                          </div>
                        </div>
                      </label>
                      <div class="variant-value">
                        {{
                          equipmentItem.price === 0
                            ? ""
                            : `+ ${equipmentItem.price} ₽ `
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-23 col-lg-8 col-xxl-7">
            <div class="price-project-block">
              <div
                class="price-project"
                v-if="arResult.arProperty.PRICE_PROJECT.VALUE"
                showScroll="priceProject"
                :class="[
                  { moveTop: elementVisibleScroll.priceProject.visible },
                ]"
              >
                <p>цена проекта</p>
                <span>{{ arResult.arProperty.PRICE_PROJECT.VALUE }} ₽</span>
              </div>
              <div
                class="print-project"
                showScroll="printProject"
                :class="[
                  { moveTop: elementVisibleScroll.printProject.visible },
                ]"
              >
                <a
                  target="_blank"
                  :href="
                    $hostname + '/upload/pdf/' + arResult.arFields.CODE + '.pdf'
                  "
                  >распечатать проект</a
                >
              </div>
            </div>
          </div>
          <div class="col-23 col-lg-15 col-xxl-16">
            <div class="characteristics">
              <separator :show="true" />
              <div class="characteristics-wrapper">
                <div
                  class="characteristics-title"
                  showScroll="characteristicsTitle"
                  :class="[
                    {
                      moveTop:
                        elementVisibleScroll.characteristicsTitle.visible,
                    },
                  ]"
                >
                  ХАРАКТЕРИСТИКИ
                </div>
                <div
                  class="characteristics-list"
                  showScroll="characteristics"
                  :class="[
                    { moveTop: elementVisibleScroll.characteristics.visible },
                  ]"
                >
                  <div
                    itemprop="description"
                    v-for="(characteristic, index) in characteristics"
                    :class="['characteristic-item', { right: index % 2 === 1 }]"
                    :key="index"
                  >
                    <div class="characteristic-label">
                      {{ characteristic.title }}
                    </div>
                    <div class="characteristic-value">
                      {{ characteristic.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="stages">
              <separator :show="true" />
              <div class="stages-wrapper">
                <div
                  class="stages-title"
                  :showScroll="'stage' + 100"
                  :class="[
                    {
                      moveTop:
                        elementVisibleScroll['stage' + 100] &&
                        elementVisibleScroll['stage' + 100].visible,
                    },
                  ]"
                >
                  ВОЗМОЖНО ПОЭТАПНОЕ СТРОИТЕЛЬСТВО
                </div>
                <div class="stages-list">
                  <div
                    v-for="(stage, i) in stages"
                    :key="i"
                    class="stage"
                    :showScroll="'stage' + i"
                    :class="[
                      {
                        moveTop:
                          elementVisibleScroll['stage' + i] &&
                          elementVisibleScroll['stage' + i].visible,
                      },
                    ]"
                  >
                    <div class="stage-icon"></div>
                    <div class="stage-label">{{ stage.label }}</div>
                    <div class="stage-title">{{ stage.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="plans-and-facades">
      <separator :show="true" />
      <h2
        showScroll="titleFacades"
        :class="[{ moveTop: elementVisibleScroll.titleFacades.visible }]"
      >
        Планировки и фасады
      </h2>
      <div class="row">
        <div class="col-xl-4 facade-col">
          <div
            class="facade-content-title text-square"
            v-if="arResult.arProperty.FACADES_CONTENT['~VALUE'] && arResult.arProperty.FACADES_CONTENT['~VALUE']['TEXT']"
            showScroll="contentTitleFacades"
            :class="[
              { moveTop: elementVisibleScroll.contentTitleFacades.visible },
            ]"
          >
            <span>В проект можно добавить:</span>
          </div>
          <div
            class="facade-content"
            v-if="arResult.arProperty.FACADES_CONTENT['~VALUE'] && arResult.arProperty.FACADES_CONTENT['~VALUE']['TEXT']"
            showScroll="contentFacades"
            :class="[{ moveTop: elementVisibleScroll.contentFacades.visible }]"
            v-html="arResult.arProperty.FACADES_CONTENT['~VALUE']['TEXT']"
          ></div>

          <div
            class="facade-img-title text-square"
            showScroll="textFacades"
            :class="[{ moveTop: elementVisibleScroll.textFacades.visible }]"
          >
            <span>фасады</span>
          </div>
          <div class="facade-img-wrap">
            <div
              class="facade-img"
              v-for="(srcImg, i) in this.arResult.arProperty.FACADES_PICTURE
                .FILE_INFO"
              :key="i"
              :showScroll="'facadeImg' + i"
              :class="[
                {
                  'show-img-project':
                    elementVisibleScroll['facadeImg' + i] &&
                    elementVisibleScroll['facadeImg' + i].visible,
                },
              ]"
              @click="openLightBox(i, 'arFacadesSrc')"
            >
              <img
                :src="$hostname + srcImg.SRC"
                :width="srcImg.WEIGHT"
                :height="srcImg.HEIGHT"
                @load="startAnimation"
              />
            </div>
          </div>
        </div>
        <div class="col-xl-14 offset-xl-3 col-plans">
          <div
            class="plan-block"
            :class="[{ multiply: elementVisibleScroll.titlePlan1.visible }]"
            v-if="arResult.arProperty.PLAN_FIRST_FLOOR.FILE_INFO.SRC"
          >
            <div
              class="text-square"
              showScroll="titlePlan1"
              :class="[{ moveTop: elementVisibleScroll.titlePlan1.visible }]"
            >
              <span>план <i>первого этажа</i></span>
            </div>
            <div
              class="mirror-plan"
              showScroll="mirrorBtn1"
              :class="[{ moveTop: elementVisibleScroll.mirrorBtn1.visible }]"
              @click="mirrorPlan('mirrorPlan1')"
            >
              показать зеркальный вариант
            </div>
            <div
              class="img-plan"
              :showScroll="'planImg1'"
              :class="[
                { 'show-img-project': elementVisibleScroll.planImg1.visible },
                { 'mirror-active': mirrorPlan1 },
              ]"
              @click="openLightBox(0, 'arPlansSrc')"
            >
              <img
                :src="
                  $hostname + arResult.arProperty.PLAN_FIRST_FLOOR.FILE_INFO.SRC
                "
                :width="arResult.arProperty.PLAN_FIRST_FLOOR.FILE_INFO.WIDTH"
                :height="arResult.arProperty.PLAN_FIRST_FLOOR.FILE_INFO.HEIGHT"
              />
            </div>
          </div>
          <div
            class="plan-block"
            :class="[{ multiply: elementVisibleScroll.titlePlan1.visible }]"
            v-if="arResult.arProperty.PLAN_SECOND_FLOOR.FILE_INFO.SRC"
          >
            <div
              class="text-square"
              showScroll="titlePlan2"
              :class="[{ moveTop: elementVisibleScroll.titlePlan2.visible }]"
            >
              <span>план <i>второго этажа</i></span>
            </div>
            <div
              class="mirror-plan"
              showScroll="mirrorBtn2"
              :class="[{ moveTop: elementVisibleScroll.mirrorBtn2.visible }]"
              @click="mirrorPlan('mirrorPlan2')"
            >
              показать зеркальный вариант
            </div>
            <div
              class="img-plan"
              :showScroll="'planImg2'"
              :class="[
                { 'show-img-project': elementVisibleScroll.planImg2.visible },
                { 'mirror-active': mirrorPlan2 },
              ]"
              @click="openLightBox(1, 'arPlansSrc')"
            >
              <img
                :src="
                  $hostname +
                  arResult.arProperty.PLAN_SECOND_FLOOR.FILE_INFO.SRC
                "
              />
            </div>
          </div>
          <div
            class="plan-block"
            :class="[{ multiply: elementVisibleScroll.titlePlan1.visible }]"
            v-if="arResult.arProperty.PLAN_ADD.FILE_INFO.SRC"
          >
            <div
              class="text-square"
              showScroll="titlePlan3"
              :class="[{ moveTop: elementVisibleScroll.titlePlan3.visible }]"
            >
              <span>{{ arResult.arProperty.PLAN_ADD_NAME.VALUE }}</span>
            </div>
            <div
              class="mirror-plan"
              showScroll="mirrorBtn3"
              :class="[{ moveTop: elementVisibleScroll.mirrorBtn3.visible }]"
              @click="mirrorPlan('mirrorPlan3')"
            >
              показать зеркальный вариант
            </div>
            <div
              class="img-plan"
              :showScroll="'planImg3'"
              :class="[
                { 'show-img-project': elementVisibleScroll.planImg3.visible },
                { 'mirror-active': mirrorPlan3 },
              ]"
              @click="openLightBox(2, 'arPlansSrc')"
            >
              <img
                :src="$hostname + arResult.arProperty.PLAN_ADD.FILE_INFO.SRC"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="affect-cost" v-if="arInfoBlock.arProperty.AFFECT_COST_TEXT.VALUE && arInfoBlock.arProperty.AFFECT_COST_TEXT.VALUE.length">
      <separator :show="true" />
      <div class="row text-block">
        <div class="col-xl-12">
            <h2
              class="affect-cost-title"
              showScroll="affectCostTitle"
              :class="[{ moveTop: elementVisibleScroll.affectCostTitle.visible }]"
            >
              {{ arInfoBlock.arProperty.AFFECT_COST_TITLE.VALUE }}
            </h2>
        </div>
          <div class="col-xl-11">
            <div class="affect-cost-text">
              <ul class="affect-cost-list">
                <li
                  class="affect-cost-item"
                  v-for="(item, i) in arInfoBlock.arProperty.AFFECT_COST_TEXT.VALUE"
                  :key="i"
                  :showScroll="'affectCostText' + i"
                  :class="[
                    {
                      moveTop:
                        elementVisibleScroll['affectCostText' + i] &&
                        elementVisibleScroll['affectCostText' + i].visible,
                      right: i >= Math.floor(arInfoBlock.arProperty.AFFECT_COST_TEXT.VALUE.length / 2),
                    },
                  ]"
                >
                  <p class="affect-cost-item-title">{{ item }}</p>
                  <span class="affect-cost-item-subtitle">{{
                    arInfoBlock.arProperty.AFFECT_COST_TEXT.DESCRIPTION[i]
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
      </div>
    </div>

    <div class="gallery-project gallery-project--interior">
      <div class="row text-block">
        <div class="col-xl-12">
          <h2
            showScroll="interiorTitle"
            :class="[{ moveTop: elementVisibleScroll.interiorTitle.visible }]"
          >
            {{ arInfoBlock.arProperty.INTERIOR_TITLE.VALUE }}
          </h2>
        </div>
        <div class="col-xl-11 d-flex" v-if="arInfoBlock.arProperty.INTERIOR_TEXT['~VALUE'] && arInfoBlock.arProperty.INTERIOR_TEXT['~VALUE']['TEXT']">
          <div
            class="gallery-project-text"
            showScroll="interiorText"
            :class="[{ moveTop: elementVisibleScroll.interiorText.visible }]"
            v-html="arInfoBlock.arProperty.INTERIOR_TEXT['~VALUE']['TEXT']"
          ></div>
        </div>
      </div>
      <swiper-gallery
        :ar-slide="arSlideInterior"
        :element-visible-scroll="elementVisibleScroll"
        :name-anim="'activeImgSlideInterior'"
        @click="openLightBox($event, 'arPictureInterior')"
        @slideChange="startAnimation"
      />
    </div>

    <div class="gallery-project gallery-project--photoreports">
      <div class="row text-block">
        <div class="col-xl-12">
          <h2
            showScroll="photoreportsTitle"
            :class="[
              { moveTop: elementVisibleScroll.photoreportsTitle.visible },
            ]"
          >
            {{ arInfoBlock.arProperty.PHOTOREPORTS_TITLE.VALUE }}
          </h2>
        </div>
        <div v-if="arInfoBlock.arProperty.PHOTOREPORTS_TEXT['~VALUE'] && arInfoBlock.arProperty.PHOTOREPORTS_TEXT['~VALUE']['TEXT']" class="col-xl-11 d-flex" >
          <div class="gallery-project-text" showScroll="photoreportsText"
            :class="[
              { moveTop: elementVisibleScroll.photoreportsText.visible },
            ]"
            v-html="arInfoBlock.arProperty.PHOTOREPORTS_TEXT['~VALUE']['TEXT']"
          ></div>
        </div>
      </div>
      <swiper-gallery
        :ar-slide="arSlidePhotoreports"
        :element-visible-scroll="elementVisibleScroll"
        :name-anim="'activeImgSlidePhotoreports'"
        @click="openLightBox($event, 'arPicturePhotoreports')"
        @slideChange="startAnimation"
      />
    </div>

    <mortgage-calculator
      v-if="currentPrice"
      :element-visible-scroll="elementVisibleScroll"
      :currentPrice="formatInNumeric(currentPrice)"
    />

    <listing-services
      v-if="arInfoBlock.arProperty.SERVICES_ID.VALUE"
      :servicesId="arInfoBlock.arProperty.SERVICES_ID.VALUE"
      :result-parent="arInfoBlock.arProperty"
      :width-order="widthOrderService"
      :element-visible-scroll="elementVisibleScroll"
    />

    <similar-project
      v-if="arResult.arProperty.SIMILAR_PROJECTS.VALUE"
      :title-component="'похожие проекты'"
      :result-parent="arResult.arProperty.SIMILAR_PROJECTS.VALUE"
      :width-order="widthOrderSimilar"
      :element-visible-scroll="elementVisibleScroll"
    />

    <popup-cool-light-box :elems="arSrc" />
    
    <modal-side :show.sync="showModal">
      <Comparison v-if="arResult && selectedDecoration.VALUE_XML_ID"
        :arResult="arResult"
        :constructor-items="constructorItems"
        :element-visible-scroll="elementVisibleScroll"
        :animationCallback="startAnimation"
        :checkboxTable="checkboxTable"
        :decorationImage="decorationsConfig[selectedDecoration.VALUE_XML_ID].imageUrl"
      />
    </modal-side>
  </div>
</template>

<script>
import { showElementScroll } from "../../../../jsFunction/showElementScroll";
import { getClBlockElement } from "../../../../jsFunction/getCIBlockElement";
import { scrollTo } from "../../../../jsFunction/scrollTo";
import { seo } from "../../../../jsFunction/seo";
import BaseButton from "../../../layout/BaseButton";
import Separator from "../../../layout/Separator";
import 'swiper/swiper-bundle.min.css';
import ListingServices from "./parts/ListingServices";
import SimilarProject from "./parts/SimilarProject";
import SwiperDetailedProjects from "../../../layout/projects/SwiperDetailedProjects";
import SwiperGallery from "../../../layout/projects/SwiperGallery";
import IconBase from "../../../IconBase";
import FavouriteIcon from "../../../icons/FavouriteIcon";
import { cookie } from "../../../../jsFunction/cookie";
import PopupCoolLightBox from "../../../layout/PopupCoolLightBox";
import MortgageCalculator from "../../../layout/projects/MortgageCalculator.vue";
import ModalSide from "../../../layout/ModalSide.vue";
import Comparison from "./parts/Comparison.vue";

export default {
  name: "ProjectStandardDetailedPage",
  mixins: [getClBlockElement, showElementScroll],
  components: {
    PopupCoolLightBox,
    SwiperDetailedProjects,
    SwiperGallery,
    SimilarProject,
    ListingServices,
    Separator,
    BaseButton,
    IconBase,
    FavouriteIcon,
    MortgageCalculator,
    ModalSide,
    Comparison,
  },
  computed: {
    getProgressAxios() {
      return this.$store.getters.getProgressAxios; // чекаем статус загузки контента
    },
    bitrixStore() {
      return this.$store.getters.cacheData;
    },
  },
  watch: {
    $route(to) {
      this.changeCode = true;
      this.changeAxios = false;
      window.setTimeout(() => {
        this.changeScreen(to);
      }, 1000);
    },
    "getProgressAxios.statusResponseAxios"(value) {
      this.changeAxios = true;
      if (value) {
        window.setTimeout(() => {
          this.startAnimation();
        }, 300);
      }
    },
  },
  data() {
    return {
      changeAxios: false,
      arResult: {
        arFields: {
          NAME: {},
        },
        arProperty: {
          ARCHITECTURAL_STYLE: [],
          AREA_PROJECT: [],
          PRICE_BUILDING: [],
          PRICE_PROJECT: [],
          SIZE_PROJECT: [],
          ROOM_PROJECT: [],
          BATHROOMS_PROJECT: [],
          WALL_PROJECT: [],
          ROOF_PROJECT: [],
          OVERLAPS_PROJECT: [],
          EXTERIOR_FINISH: [],
          FACADES_PICTURE: [],
          PLAN_FIRST_FLOOR: { FILE_INFO: {} },
          PLAN_SECOND_FLOOR: { FILE_INFO: {} },
          PLAN_ADD: { FILE_INFO: {} },
          SIMILAR_PROJECTS: [],
          PLAN_ADD_NAME: [],
          PRICE_MORTGAGE: [], 
          FOUNDATION_PROJECT: [], 
          FACE_PROJECT: [], 
          VARIANTS_PROJECT: [],  
          PRICE_FOUNDATION_1: [], 
          PRICE_FOUNDATION_2: [],  
          PRICE_WALL_1: [], 
          PRICE_WALL_2: [],  
          PRICE_VARIANT_STANDART: [],  
          PRICE_VARIANT_COMFORT: [],  
          FACADES_CONTENT: [],  
          INTERIOR_PHOTOGALLERY: {},  
          INTERIOR_VIDEOGALLERY: {}, 
          PHOTOREPORTS_PHOTOGALLERY: {},
          PHOTOREPORTS_VIDEOGALLERY: {},
        },
      },
      arInfoBlock: {
        arProperty: {
          ADJUSTMENT_TITLE: {},
          ADJUSTMENT_TEXT: {
            "~VALUE": "",
          },
          SERVICES_ID: {},
          SERVICES_TITLE: {},
          SERVICES_TEXT: {
            "~VALUE": "",
          },
          INTEREST_RATES_LIST: {},
          AFFECT_COST_TITLE: {},
          AFFECT_COST_TEXT: {},
          INTERIOR_TITLE: {},
          INTERIOR_TEXT: {},
          PHOTOREPORTS_TITLE: {},
          PHOTOREPORTS_TEXT: {},
        },
      },
      arSlidePicture: [],
      arPictureInterior: [],
      arSlideInterior: [],
      arPicturePhotoreports: [],
      arSlidePhotoreports: [],
      arFacadesSrc: [],
      arPlansSrc: [],
      elementVisibleScroll: {
        title: {}, consultationBtn1: {}, consultationBtn2: {}, projectAdjustment: {}, projectsBtn1: {}, like: {},
        architectural: {}, area: {}, priceBuild: {}, decoration: {}, mortgage: {}, specificationsTitle: {}, priceProject: {},
        printProject: {}, priceBuild2: {}, specificationsDetail: {}, titleFacades: {}, textFacades: {}, activeImgSlide: {},
        titlePlan1: {}, mirrorBtn1: {}, planImg1: {}, titlePlan2: {}, mirrorBtn2: {}, planImg2: {}, titlePlan3: {}, mirrorBtn3: {},
        planImg3: {}, adjustmentProject: {}, adjustmentText: {}, servicesProject: {}, servicesText: {}, similarTitle: {}, mortgageRates: {},
        configurations: {}, calculateMortgage: {}, contentFacades: {}, contentTitleFacades: {}, affectCostTitle: {}, affectCostText: {}, activeImgSlideInterior: {},
        interiorTitle: {}, interiorText: {}, activeImgSlidePhotoreports: {}, photoreportsTitle: {}, photoreportsText: {}, titleCalculator: {},
        descCalculator: {}, monthPayment: {}, monthPaymentPrice: {}, textResultPrice: {}, btnConsul2: {}, mortgageMore: {}, characteristicsTitle: {},
        characteristics: {}, foundationHead: {}, foundationVariants: {}, wallHead: {}, wallVariants: {}, packageHead: {}, packageVariants: {},
        constructorHead: {}, comparisonTitle: {}, tableItem: {}, comparsionDopUsligi: {}, comparisonTableTitle: {},
        navBtn: {}, comparisonPrice: {}, rowTable: {}, priceTextMaterial: {}
      },
      arElementsShow: [],
      arSort: {
        SORT: "ASC",
      },
      arFilter: {
        IBLOCK_ID: 23,
        ACTIVE: "Y",
        CODE: this.$route.params.code,
      },
      arSelect: ["ID", "NAME", "CODE", "PREVIEW_PICTURE", "DETAIL_PAGE_URL"],
      arNuvParam: {},
      widthOrderService: [
        "col-xxl-7 col-md-11 left-side",
        "col-xxl-10 col-md-12 right-side",
        "col-xxl-6 col-md-11 right-side offset-right-1",
      ],
      widthOrderSimilar: ["col-lg-12", "col-lg-11"],
      mirrorPlan1: false,
      mirrorPlan2: false,
      mirrorPlan3: false,
      fixedBtn: false,
      checkFavourite: false,
      changeCode: false,
      arSrc: [],
      showModal: false,
      basePrice: 0,
      currentPrice: 0,
      selectedPrices: {},
      decorationsConfig: {
        BRICK: {
          title: "кирпич",
          imageUrl: require("@/assets/icon/decoration-1.jpg"),
        },
        PLASTER: {
          title: "штукатурка",
          imageUrl: require("@/assets/icon/decoration-2.jpg"),
        },
      },
      decorations: [],
      selectedDecoration: {},
      stages: [
        { label: "1 этап", title: "Фундамент" },
        { label: "2 этап", title: "Коробка" },
        { label: "3 этап", title: "Фасад" },
      ],
      materialOptions: {
        foundation: [],
        wall: [],
      },
      equipment: [],
      constructorItems: [],
      characteristics: [],
      minimalMortage: 0,
      twins: [],
      checkboxTable: [],
    };
  },
  methods: {
    openLightBox(i, variable) {
      this.includeHostName(this[variable]);
      this.$store.dispatch("receiveArSrcImg", { ind: i });
    },
    includeHostName(arSrc) {
      this.arSrc = [];
      for (let i in arSrc) {
        this.arSrc[i] = {
          src: this.$hostname + arSrc[i].SRC,
          title: "",
        };
      }
      return this.arSrc;
    },
    changeScreen(to) {
      this.arFilter.CODE = to.params.code;
      window.scrollTo(0, 0);
      this.changeCode = false;

      let cacheId = this.arFilter.IBLOCK_ID + "_" + to.params.code;
      if (!this.bitrixStore[cacheId]) {
        getClBlockElement.methods
          .GetList(
            this.arSort,
            this.arFilter,
            false,
            this.arNuvParam,
            this.arSelect
          )
          .then((data) => {
            this.setProject(data);

            this.arResult.arFields.NAME = this.arResult.arFields.NAME.replace(
              "проект",
              "№"
            );
            this.$store.dispatch("receiveCheckPosition"); // заставляем пересмотреть футер положение своих элементов

            this.$store.dispatch("receiveCacheData", {
              id: cacheId,
              cacheData: data,
            });
          });
      } else {
        this.setProject(this.bitrixStore[cacheId]);

        this.arResult.arFields.NAME = this.arResult.arFields.NAME.replace(
          "проект",
          "№"
        );

        setTimeout(() => {
          this.$store.dispatch("receiveCheckPosition"); // заставляем пересмотреть футер положение своих элементов

          this.startAnimation();
        }, 200);
      }
    },
    handleScroll() {
      let visibleStatus = showElementScroll.methods.comparisonPosition(
        this.arElementsShow
      );
      this.setObjectAnimation(visibleStatus);

      if (window.innerWidth <= 768) {
        this.fixedBtn = true;
      } else {
        this.fixedBtn = false;
      }
    },
    startAnimation() {
      this.arElementsShow = showElementScroll.methods.GetElements(
        ".project-detailed-page"
      );
      let visibleStatus = showElementScroll.methods.comparisonPosition(
        this.arElementsShow
      );
      this.setObjectAnimation(visibleStatus);
      setTimeout(() => {
        this.$store.dispatch("receiveCheckPosition"); // заставляем пересмотреть футер положение своих элементов
      }, 200);
    },
    setObjectAnimation(visibleStatus) {
      for (let item in this.arElementsShow) {
        this.$set(this.elementVisibleScroll, item, this.arElementsShow[item]);
        if (typeof visibleStatus === "boolean") {
          this.$set(this.elementVisibleScroll[item], "visible", visibleStatus);
        } else {
          this.$set(
            this.elementVisibleScroll[item],
            "visible",
            visibleStatus[item].visible
          );
        }
      }
    },
    handleResize() {
      this.startAnimation();
    },
    mirrorPlan(variableMirror) {
      if (this[variableMirror] === true) {
        this[variableMirror] = false;
      } else {
        this[variableMirror] = true;
      }
    },
    setFavourite() {
      let arFavouriteId = [];

      let cookies = cookie.methods.get("favouriteId");

      if (cookies === null || cookies === "") {
        // если куки пустые
        arFavouriteId[0] = this.arResult.arFields.ID; // записываем 1 элемент в массив
        this.checkFavourite = true;
      } else {
        // если куки не пустой
        arFavouriteId = cookies.split(","); // получаем из куки ID проекта разделяя их по символу ','
        let indexId = arFavouriteId.indexOf(this.arResult.arFields.ID); // узнаем номер ID в массиве
        if (indexId !== -1) {
          // если ID есть в массиве
          arFavouriteId.splice(indexId, 1); // удаляем этот ID
          this.checkFavourite = false;
        } else {
          // если ID нет в массиве
          arFavouriteId.push(this.arResult.arFields.ID); // добавляем ID в массив
          this.checkFavourite = true;
        }
      }
      cookie.methods.set("favouriteId", arFavouriteId, 45); // сохраняем новый массив с ID выбранных проектов в куки
    },
    checkFavouriteId() {
      let arFavouriteId = [];
      if (
        cookie.methods.get("favouriteId") === null ||
        cookie.methods.get("favouriteId") === ""
      ) {
        // если куки пустые
        this.checkFavourite = false;
      } else {
        // если куки не пустой
        arFavouriteId = cookie.methods.get("favouriteId").split(","); // получаем из куки ID проекта разделяя их по символу ','
        let indexId = arFavouriteId.indexOf(this.arResult.arFields.ID); // узнаем номер ID в массиве
        if (indexId !== -1) {
          // если ID есть в массиве
          this.checkFavourite = true;
        } else {
          // если ID нет в массиве
          this.checkFavourite = false;
        }
      }
    },
    openCallBackForm(id, title) {
      let getOnlyFields = "ALL",
        sort = "s_sort",
        order = "asc",
        arFilter = {
          ACTIVE: "Y",
        };
      this.$store.dispatch("receiveParamForm", {
        id: id,
        getOnlyFields: getOnlyFields,
        sort: sort,
        order: order,
        arFilter: arFilter,
        title: title,
      });
      this.$metrika.reachGoal("PROEKT");
    },
    formatPrice(price) {
      return price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .trim();
    },
    formatInNumeric(value) {
      value = value.toString();
      return value.toString().trim() === ""
        ? 0
        : parseInt(value.replace(/\s/g, ""), 10);
    },
    updatePrice(event) {
      const { name, value } = event.target;

      const numericValue = this.formatInNumeric(value);
      this.selectedPrices[name] = numericValue;
      this.updateTotalPrice();
    },
    updateTotalPrice() {
      const basePrice = parseInt(this.basePrice.replace(/\s/g, ""), 10);
      let totalPrice = basePrice;
      Object.values(this.selectedPrices).forEach((item) => {
        totalPrice = totalPrice + item;
      });
      this.currentPrice = this.formatPrice(totalPrice);
      this.recalculateMinimalMortage();
    },
    updateDecoration(decoration) {
      const newUrl = `/standard-projects/${decoration.data.ITEMS[0].arFields.CODE}`; // Новый URL
      window.history.pushState({}, "", newUrl);
      this.setProject(decoration.data, false);
    },
    scrollToSection() {
      scrollTo("mortgage-price");
    },
    async setProject(data, isFirst = true) {
      if (typeof data.ITEMS !== "undefined") {
        this.arResult = data.ITEMS[0];

        seo.methods.set(this.arResult, "detail");
        this.arInfoBlock = data.infoBlock.GENERAL_INFORMATION;

        // Вычисляем цену и текущую цену
        this.basePrice = this.arResult.arProperty.PRICE_BUILDING.VALUE;
        this.currentPrice = this.arResult.arProperty.PRICE_BUILDING.VALUE;

        this.arSlidePicture = [data.ITEMS[0].arFields.PREVIEW_PICTURE_INFO];
        this.arSlidePicture = this.arSlidePicture.concat(
          data.ITEMS[0].arProperty.FACADES_PICTURE.FILE_INFO
        );

        this.arPictureInterior = this.arPictureInterior.concat(
          data.ITEMS[0].arProperty.INTERIOR_PHOTOGALLERY.FILE_INFO
        );
        this.arSlideInterior = [];
        this.arSlideInterior = this.arSlideInterior.concat(
          data.ITEMS[0].arProperty.INTERIOR_PHOTOGALLERY.FILE_INFO
        );
        this.arSlideInterior = this.arSlideInterior.concat(
          data.ITEMS[0].arProperty.INTERIOR_VIDEOGALLERY.VALUE
        );

        this.arPicturePhotoreports = this.arPicturePhotoreports.concat(
          data.ITEMS[0].arProperty.PHOTOREPORTS_PHOTOGALLERY.FILE_INFO
        );
        this.arSlidePhotoreports = [];
        this.arSlidePhotoreports = this.arSlidePhotoreports.concat(
          data.ITEMS[0].arProperty.PHOTOREPORTS_PHOTOGALLERY.FILE_INFO
        );
        this.arSlidePhotoreports = this.arSlidePhotoreports.concat(
          data.ITEMS[0].arProperty.PHOTOREPORTS_VIDEOGALLERY.VALUE
        );

        this.arFacadesSrc = data.ITEMS[0].arProperty.FACADES_PICTURE.FILE_INFO;

        this.arPlansSrc[0] =
          data.ITEMS[0].arProperty.PLAN_FIRST_FLOOR.FILE_INFO;
        this.arPlansSrc[1] =
          data.ITEMS[0].arProperty.PLAN_SECOND_FLOOR.FILE_INFO;
        this.arPlansSrc[2] = data.ITEMS[0].arProperty.PLAN_ADD.FILE_INFO;

        this.arResult.arFields.NAME = this.arResult.arFields.NAME.replace(
          "проект",
          "№"
        );

        

        //Из массива конструктора дома вытаскиваем значения
        const constructorItems = [];

        data.ITEMS[0].arProperty.HOUSE_CONSTRUCTOR_TABLE.VALUE.forEach(
          (item) => {
            const index = constructorItems.findIndex((constructorItem) => {
              return (
                constructorItem.title ===
                item.SUB_VALUES.HOUSE_CONSTRUCTOR_TITLE.VALUE.toLowerCase().trim()
              );
            });
            const property = {
              subtitle: item.SUB_VALUES.HOUSE_CONSTRUCTOR_SUBTITLE.VALUE,
              description:
                item.SUB_VALUES.HOUSE_CONSTRUCTOR_DESCRIPTION &&
                item.SUB_VALUES.HOUSE_CONSTRUCTOR_DESCRIPTION.VALUE,
              price: Number(item.SUB_VALUES.HOUSE_CONSTRUCTOR_PRICE.VALUE),
              material:
                item.SUB_VALUES.HOUSE_CONSTRUCTOR_MATERIAL &&
                item.SUB_VALUES.HOUSE_CONSTRUCTOR_MATERIAL.VALUE_XML_ID,
            };
            if (constructorItems[index]) {
              constructorItems[index].properties.push(property);
            } else {
              constructorItems.push({
                title:
                  item.SUB_VALUES.HOUSE_CONSTRUCTOR_TITLE.VALUE.toLowerCase().trim(),
                properties: [property],
              });
            }
          }
        );
        this.selectedDecoration = data.ITEMS[0].arProperty.DECORATION;

        this.checkboxTable = [];
        data.ITEMS[0].arProperty.CHECKBOX_TABLE.VALUE.forEach((item) => {
          const index = this.checkboxTable.findIndex((item2) => {
            return (
              item2.title ===
              item.SUB_VALUES.CHECKBOX_TABLE_TITLE.VALUE.toLowerCase().trim()
            );
          });
          
          const newItem = {
            subtitle: item.SUB_VALUES.CHECKBOX_TABLE_SUBTITLE.VALUE,
            prompt:
              item.SUB_VALUES.CHECKBOX_TABLE_PROMPT &&
              item.SUB_VALUES.CHECKBOX_TABLE_PROMPT.VALUE,
            first:
              item.SUB_VALUES.CHECKBOX_FIRST_CELL.VALUE_XML_ID ===
              "FACADE_PHOTO"
                ? "FACADE_PHOTO"
                : item.SUB_VALUES.CHECKBOX_FIRST_CELL.VALUE_XML_ID,
            second:
              item.SUB_VALUES.CHECKBOX_SECOND_CELL.VALUE_XML_ID ===
              "FACADE_PHOTO"
                ? "FACADE_PHOTO"
                : item.SUB_VALUES.CHECKBOX_SECOND_CELL.VALUE_XML_ID,
            third:
              item.SUB_VALUES.CHECKBOX_THIRD_CELL.VALUE_XML_ID ===
              "FACADE_PHOTO"
                ? "FACADE_PHOTO"
                : item.SUB_VALUES.CHECKBOX_THIRD_CELL.VALUE_XML_ID,
          };
          if (this.checkboxTable[index]) {
            this.checkboxTable[index].items.push(newItem);
          } else {
            this.checkboxTable.push({
              title:
                item.SUB_VALUES.CHECKBOX_TABLE_TITLE.VALUE.toLowerCase().trim(),
              items: [newItem],
            });
          }
        });

        this.equipment = constructorItems.find(
          (item) => item.title === "комплектация"
        );
        this.constructorItems = constructorItems;

        this.characteristics =
          data.ITEMS[0].arProperty.CHARACTERISTICS_TABLE.VALUE.map((item) => {
            return {
              title: item.SUB_VALUES.CHARACTERISTICS_TITLE.VALUE,
              text: item.SUB_VALUES.CHARACTERISTICS_TEXT.VALUE,
            };
          });
        if (isFirst) {
          for (const twin of data.ITEMS[0].arProperty.TWINS.VALUE) {
            const filter = {
              IBLOCK_ID: 23,
              ACTIVE: "Y",
              ID: Number(twin),
            };
            let cacheId = this.arFilter.IBLOCK_ID + "_" + filter.ID;
            if (!this.bitrixStore[cacheId]) {
              getClBlockElement.methods
                .GetList(
                  this.arSort,
                  filter,
                  false,
                  this.arNuvParam,
                  this.arSelect
                )
                .then((dataFromApi) => {
                  this.$store.dispatch("receiveCacheData", {
                    id: cacheId,
                    cacheData: dataFromApi,
                  });
                  this.twins.push(dataFromApi);
                  this.recalculateDecorations(data);
                });
            } else {
              this.twins.push(this.bitrixStore[cacheId]);
              this.recalculateDecorations(data);
            }
          }
          
          this.recalculateMinimalMortage();
          this.checkFavouriteId();
        } else {
          //this.$router.push("/404");
        }
      }
    },
    recalculateMinimalMortage() {
      this.minimalMortage = this.formatPrice(
        Math.round(
          (Number(this.currentPrice.replaceAll(" ", "")) / 30 / 12) * 1.001
        )
      );
    },
    recalculateDecorations(data) {
      this.decorations = [];
      this.decorations.push({
        data,
        decoration:
          this.decorationsConfig[
            data.ITEMS[0].arProperty.DECORATION.VALUE_XML_ID
          ],
      });

      this.twins.forEach((item) => {
        this.decorations.push({
          data: item,
          decoration:
            this.decorationsConfig[
              item.ITEMS[0].arProperty.DECORATION.VALUE_XML_ID
            ],
        });
      });
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);

    let cacheId = this.arFilter.IBLOCK_ID + "_" + this.$route.params.code;
    if (!this.bitrixStore[cacheId]) {
      getClBlockElement.methods
        .GetList(
          this.arSort,
          this.arFilter,
          false,
          this.arNuvParam,
          this.arSelect
        )
        .then((data) => {
          this.setProject(data);
          this.$store.dispatch("receiveCacheData", {
            id: cacheId,
            cacheData: data,
          });
        });
    } else {
      this.setProject(this.bitrixStore[cacheId]);
    }
  },

  mounted() {
    if (!this.changeAxios) {
      this.startAnimation();
    }
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped></style>
