<template>
  <div class="dropdown-container">
    <button class="dropdown-button" @click="toggleDropdown">?</button>
    <div v-bind:class="{ 'dropdown-content': true, show: isOpen }">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: { text: String },

  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>

</style>
