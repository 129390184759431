<template>
    <div class="project-cell-menu" :class="{ 'open': toggleAccordion }">
        <div class="title-wrap-menu">
            <h2>Проекты</h2><span>{{ countAll }}</span>
            <icon-base :width="14" :height="12" :class="'triangle-toggle-btn'"><triangle-down /></icon-base>
        </div>

        <div class="accordion-body" :style="styleAccordion">
            <div class="section-list">
                <div class="link-list">
                    <a class="hide-line projects-title" :class="[{ 'active': '/individual-projects' === normalizePath($route.path) }]" :href="'/individual-projects/'" @click="changePage('/individual-projects/')">
                        индивидуальные проекты
                    </a>

                    <a class="hide-line filter-params" :href="'/individual-projects/?&PROPERTY_AREA_FILTER=' + property.ID"
                        v-for="(property, i) in filterArea" :key="i" @click="changePage('/individual-projects/?&PROPERTY_AREA_FILTER=' + property.ID)"
                        v-html="property.VALUE" />

                    <a class="hide-line projects-title" :class="[{ 'active': '/standard-projects' === normalizePath($route.path) }]" :href="'/standard-projects/'"
                        @click="changePage('/standard-projects/')">
                        типовые проекты
                    </a>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { getClBlockElement } from "../../../jsFunction/getCIBlockElement";
import IconBase from "../../IconBase";
import TriangleDown from "../../icons/TriangleDown";

export default {
    name: "MenuCellProject",
    components: { TriangleDown, IconBase },
    props: ['toggleAccordion'],
    data() {
        return {
            filterArea: [],
            countAll: '',
            styleAccordion: '',
            arSort: {
                'SORT': 'ASC'
            },
            arFilter: {
                'IBLOCK_ID': 9, 'ACTIVE': 'Y',
            },
            arSelect: [
                'NAME', 'CODE', 'PREVIEW_PICTURE', 'DETAIL_PAGE_URL', 'GET_FILTER'
            ],
            arNuvParam: {
                'iNumPage': 1, 'nPageSize': 1, 'realPageSize': 1,
            },
            lazyLoadPage: {
                'iNumPage': 1,
                'nPageSize': 4,
            },
        }
    },
    watch: {
        toggleAccordion: function (value) {
            if (value) {
                this.openAccordion();
            } else {
                this.closeAccordion();
            }
        }
    },
    methods: {
        changePage(path) {
            event.preventDefault();
            if (path !== this.$route.path && path !== '') {
                this.$router.push(path);
                this.$emit('closeRightMenu');
            }
        },
        openAccordion() {
            let heightAccordionBody = document.querySelectorAll('.project-cell-menu .section-list')[0].clientHeight;
            this.styleAccordion = 'height:' + heightAccordionBody + 'px;';
        },
        closeAccordion() {
            this.styleAccordion = '';
        },
        normalizePath(path) {
            return path.replace(/\/+$/, '');
        }
    },
    created() {
        getClBlockElement.methods.GetList(this.arSort, this.arFilter, false, this.arNuvParam, this.arSelect).then((data) => {
            this.filterArea = data.infoBlock.FILTERS.AREA_FILTER.arProperty;
            this.countAll = data.infoBlock.ALL_COUNT_ITEM;
        });
    }
}
</script>

<style scoped></style>