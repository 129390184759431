<template>
    <g>
        <circle cx="31" cy="31" r="30" fill="white" />
        <path d="M41.6228 29.9145C42.4567 30.3955 42.4567 31.5991 41.6228 32.0801L25.3746 41.452C24.5412 41.9327 23.5 41.3313 23.5 40.3693L23.5 21.6254C23.5 20.6634 24.5412 20.0619 25.3746 20.5426L41.6228 29.9145Z" fill="#383838" />
    </g>
</template>

<script>
export default {
    name: "VideoIconRound"
}
</script>

<style scoped></style>